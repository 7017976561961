import * as React from "react"
import Layout from "../components/layout"
import {
    UserIcon,
    UserGroupIcon,
  } from '@heroicons/react/outline'

const groepen = [
    {
      name: 'Sloebers',
      age: '1ste en 2de leerjaar',
      href: '/sloebers',
      icon: UserIcon,
    },
    {
      name: 'Korbels',
      age: '3de en 4de leerjaar',
      href: '/korbels',
      icon: UserIcon,
    },
    {
      name: "Rakwi's",
      age: '5de en 6de leerjaar',
      href: '/rakwis',
      icon: UserIcon,
    },
    {
      name: "Tito's",
      age: '1ste en 2de middelbaar',
      href: '/titos',
      icon: UserGroupIcon,
    },
    {
      name: "Keti's",
      age: '3de en 4de middelbaar',
      href: '/ketis',
      icon: UserGroupIcon,
    },
    {
      name: "Aspi's",
      age: '5de en 6de middelbaar',
      href: '/aspis',
      icon: UserGroupIcon,
    },
  ]

// markup
const KampPage = () => {

  return (
    <Layout pageTitle={'Groepen'}>
        <div class="py-6 px-8">
            {groepen.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className="-m-3 px-3 py-5 flex items-start rounded-lg hover:bg-gray-50"
                            >
                              <item.icon className="flex-shrink-0 h-6 w-6 text-red-600" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                                <p className="mt-1 text-sm text-gray-500">{item.age}</p>
                              </div>
                            </a>
                          ))}
         </div>
    </Layout>
  )
}

export default KampPage